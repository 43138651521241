import { Button } from "antd";
import LogoContainer from "../Common/Logo-container";

const InfoPage = ({ closeHandler }: any) => {
  return (
    <div
      className="thank-you-container info"
      style={{ backgroundColor: "#F8F9FB" }}
    >
      {/* <div>
        <img src={logo} alt="go2-logo" className="mb-l" width="200px" />
      </div> */}
      <div className="title-header mt-2">Welcome!</div>
      <div className="title-desc mb-5">Dear Lung Cancer Colleague,</div>
      <div className="title-desc mb-5">
        Thank you for considering taking this survey. GO2 for Lung Cancer, a
        lung cancer patient advocacy organization, is seeking to understand
        current access to essential resources and the ability to offer Small
        Cell Lung Cancer patients a novel bispecific CD3/T-cell engager therapy
        that has recently shown promise in the effective management of
        extensive-stage Small Cell Lung Cancer.
      </div>
      <div className="title-desc mb-5">
        We invite you and other colleagues at your facility to complete this
        brief, 10-minute survey. We aim to ensure that we include a broad range
        of care team perspectives with varying professional, health system, and
        geographic experiences; please feel free to forward this survey to
        anyone on your care team—nurses, physicians, nurse practitioners,
        physician assistants, navigators, care managers, pharmacists and
        clinic/program administrators.
      </div>
      <div className="title-desc mb-5">
        We're available if you have any questions at BEREADI@go2.org or (972)
        666-0552.
      </div>
      <Button
        className="tu-button"
        onClick={closeHandler}
        type="primary"
        style={{ flexShrink: 0 }}
      >
        Take me to the survey
      </Button>
      <LogoContainer />
      <div className="footer-text">
        This project is supported by GO2 for Lung Cancer and Amgen.
      </div>
    </div>
  );
};

export default InfoPage;
